import FileUploadButton from './components/FileUploadButton';
// ...
function App() {
  return (
    <div class="min-h-screen bg-gray-100 p-8 flex font-custom">
      {/* <!-- Sidebar --> */}
      <div class="w-1/4 bg-white p-6 rounded space-y-4">
        <div class="text-purple-600 font-bold text-xl mb-4">Encore</div>
        <a href="#" class="block hover:bg-gray-200 p-2 rounded">Dashboard</a>
      </div>

      {/* <!-- Main content --> */}
      <div class="flex-1 ml-8">
        <div class="bg-white p-6 rounded">
          <h2 class="text-xl font-bold mb-4">Dashboard</h2>
          <div class="grid grid-cols-3 gap-8">
            <div class="bg-gray-100 p-4 rounded">
              <FileUploadButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
