import React, { useState, useRef, useEffect } from 'react';
import { BACKEND_URL } from '../constants'

const FileUploadButton = () => {
    const [isFileMenuVisible, setFileMenuVisible] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const fileInput = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!fileInput.current.files[0]) {
            alert("Please select a file first.");
            return;
        }

        const formData = new FormData();
        formData.append("file", fileInput.current.files[0]);

        try {
            const response = await fetch(`${BACKEND_URL}/upload`, {
                method: "POST",
                body: formData,
            });
            const data = await response.json();

            if (response.ok) {
                alert("File uploaded successfully");
                console.log("Uploaded file URL:", data.url);
            } else {
                alert(`Failed to upload file: ${data.error}`);
            }
        } catch (error) {
            console.error("There was an error uploading the file:", error);
        }
    };

    const toggleFileMenu = () => {
        setFileMenuVisible(!isFileMenuVisible);
        if (!isFileMenuVisible) {
            fileInput.current.click();
        }
    };

    useEffect(() => {
        if (fileSelected) {
            handleSubmit(new Event('submit'));
            setFileSelected(false);
        }
    }, [fileSelected]);

    return (
        <div>
            <label>
                Upload file to get financial filing analysis:
                <input
                    type="file"
                    ref={fileInput}
                    className="hidden"
                    onChange={() => setFileSelected(true)}
                />
            </label>
            <button type="submit" onClick={toggleFileMenu} className="bg-white mt-3 text-purple-600 font-medium px-6 py-2 rounded-full border border-purple-600 flex items-center justify-center hover:bg-purple-600 hover:text-white transition duration-300">
                <svg className="mr-2 h-4 w-4 text-purple-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 2a1 1 0 00-1 1v6H3a1 1 0 100 2h5v6a1 1 0 102 0v-6h5a1 1 0 100-2h-5V3a1 1 0 00-1-1z"></path>
                </svg>
                Add file
            </button>
        </div>
    );
}

export default FileUploadButton;
